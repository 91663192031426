<template>
  <ExpandedCard title="Invoices" :loading="loading_items">
    <ViewInvoice
      v-model="view_invoice_dialog"
      :invoice="view_item"
    ></ViewInvoice>
    <template v-slot:default>
      <v-row dense>
        <v-col :cols="mdAndUp ? 2 : 12">
          <v-sheet class="d-flex fullwidth" height="100">
            <div class="align-self-center mx-auto">
              <strong class="d-block fs-25 font-weight-bold primary--text">
                Today
              </strong>
              <h1 class="mt-2 primary--text fs-23 font-weight-bold text-center">
                {{ totalToday }}
              </h1>
            </div>
          </v-sheet>
          <v-sheet class="d-flex fullwidth" height="100">
            <div class="align-self-center mx-auto">
              <strong class="d-block fs-25 font-weight-bold primary--text">
                This Week
              </strong>
              <h1 class="mt-2 primary--text fs-23 font-weight-bold text-center">
                {{ totalWeek }}
              </h1>
            </div>
          </v-sheet>
          <v-sheet class="d-flex fullwidth" height="100">
            <div class="align-self-center mx-auto">
              <strong class="d-block fs-25 font-weight-bold primary--text">
                This Month
              </strong>
              <h1 class="mt-2 primary--text fs-23 font-weight-bold text-center">
                {{ totalMonth }}
              </h1>
            </div>
          </v-sheet>
          <v-sheet class="d-flex fullwidth" height="100">
            <div class="align-self-center mx-auto">
              <strong class="d-block fs-25 font-weight-bold primary--text">
                Total Paid
              </strong>
              <h1 class="mt-2 primary--text fs-23 font-weight-bold text-center">
                {{ stats.total_paid | money }}
              </h1>
            </div>
          </v-sheet>
          <v-sheet v-if="mdAndUp" class="d-flex fullwidth" height="200">
            <div class="align-self-center mx-auto">
              <v-avatar :size="200" tile>
                <v-img :lazy-src="settings.loader" contain :src="image"></v-img>
              </v-avatar>
            </div>
          </v-sheet>
        </v-col>
        <v-col :cols="mdAndUp ? 10 : 12">
          <apexchart
            class="border-1 py-3 border-color--grey rounded-10 mb-1"
            height="150"
            type="area"
            :options="chartOptions"
            :series="series"
          ></apexchart>
          <VueTable
            :items="items"
            :show-top-header="false"
            :headers="headers"
            :show-row-actions="false"
            icon="mdi-cash-register"
            :loading="loading_items"
            empty-text="No invoice yet"
            :no-more-data="!has_more_items"
            :show-select="false"
            @load-more="loadMoreItems"
          >
            <template v-slot:row-slot="{ item }">
              <td>{{ item.id_normalize }}</td>
              <td class="text-center">{{ item.title | trunc_mid(15, 15) }}</td>
              <td class="text-center">
                <Avatar :user="item.billable_to"></Avatar>
              </td>
              <td>
                <Avatar :user="item.billable_from"></Avatar>
              </td>
              <td class="text-center">{{ item.due_date | format('ll') }}</td>
              <td class="text-center">{{ item.total | money }}</td>
              <td class="text-center">{{ item.status | ucwords }}</td>
              <td>
                <v-icon
                  class="view-invoice"
                  color="primary"
                  @click="open_view_dialog(item)"
                >
                  mdi-eye-outline
                </v-icon>
              </td>
            </template>
          </VueTable>
        </v-col>
      </v-row>
    </template>
  </ExpandedCard>
</template>

<script>
import ExpandedCard from '../components/ExpandedCard.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
import Actions from '@/common/VueTable/ActionDropdown.vue'
import ViewInvoice from '@/modules/Invoice/components/ViewInvoice/ViewInvoice.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
export default {
  mixins: [fetch_services],
  components: { ExpandedCard, VueTable, Actions, ViewInvoice },
  data: (vm) => ({
    image: require('@/assets/svgs/images/card-invoice.svg?internal').default,
    filter: 'All',
    filters: ['All', 'Paid', 'Pending'],
    stats: {
      today: {
        paid: 0,
        pending: 0
      },
      week: {
        paid: 0,
        pending: 0
      },
      month: {
        paid: 0,
        pending: 0
      },
      total_paid: 0
    },
    view_item: null,
    view_invoice_dialog: false,
    headers: [
      { text: 'Invoice ID', align: 'left', sortable: false, width: 100 },
      { text: 'Name', align: 'center', sortable: false },
      { text: 'Billed To', align: 'center', sortable: false },
      { text: 'Billed From', align: 'center', sortable: false },
      { text: 'Date', align: 'center', sortable: false },
      { text: 'Amount', align: 'center', sortable: false },
      { text: 'Status', align: 'center', sortable: false },
      { icon: 'mdi-menu', align: 'left', sortable: false }
    ],
    series: [
      {
        name: 'Paid',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: 'Pending',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'month',
        categories: vm.calendarMonths()
      }
    }
  }),
  computed: {
    today_series() {
      return [this.stats.today.paid, this.stats.today.pending]
    },
    totalToday() {
      return this.stats
        ? Number(this.stats.today.paid) + Number(this.stats.today.pending)
        : 0
    },
    totalWeek() {
      return this.stats
        ? Number(this.stats.week.paid) + Number(this.stats.week.pending)
        : 0
    },
    totalMonth() {
      return this.stats
        ? Number(this.stats.month.paid) + Number(this.stats.month.pending)
        : 0
    }
  },
  created() {
    this.getData()
    this.getInvoiceMonthlyStatistics()
    this.loadItems(`api/invoice?type=one-time`, true, null, null)
  },
  methods: {
    open_view_dialog(data) {
      this.view_item = data
      // this.view_invoice_dialog = true
      this.popupCenter({
        url: data.view_url,
        title: data.title,
        w: 900,
        h: 550
      })
    },
    getData() {
      this.loading_items = true
      request
        .get(`api/dashboard-contents/invoices`)
        .then(({ data }) => {
          this.stats = data
        })
        .finally(() => {
          this.loading_items = false
        })
    },
    getInvoiceMonthlyStatistics() {
      this.loading_items = true
      request
        .get(`api/dashboard-contents/invoices/monthly-statistics`)
        .then(({ data }) => {
          this.series = data
        })
        .finally(() => {
          this.loading_items = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/sass/_variables';
.view-invoice:hover {
  cursor: pointer;
}
</style>
